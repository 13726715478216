import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import Icon from '../Icons';
import PostsIcon from '../Icons/PostsIcon';

const SideNav = () => {
    const { pathname } = useLocation();
    const activeLink = pathname.split("/")[1];
    const navList = [
        { name: "Dashboard", iconId: "dashboard-icon", tab: "/dashboard", pathName: "dashboard"},
        { name: "Bookings", iconId: "bookings-icon", tab: "/bookings", pathName: "bookings"},
        { name: "Applications", iconId: "application-icon", tab: "/application", pathName: "application"},
        { name: "Schools", iconId: "school-icon", tab: "/schools", pathName: "schools"},
        { name: "Users", iconId: "users-icon", tab: "/users", pathName: "users"},
        { name: "Admin", iconId: "admin-icon", tab: "/admin", pathName: "admin"},
        { name: "Posts", iconId: "posts-icon", tab: "/posts", pathName: "posts"},
    ]

    const handleActiveLink = (param: string) => {
        if (param === activeLink) {
          return "bg-primary mx-4 py-4 pl-8 pr-8 rounded-[8px] text-white";
        }
        return "mx-4 py-4 pl-8 pr-8 text-[#8B8BA4]"
    };

  return (
    <div className=" w-[250px] left-nav h-screen overflow-y-auto bg-white" >
        <Link to='/'>
            <img className="mb-16 mt-10 object-contain mx-auto" alt="logo" src={`${process.env.PUBLIC_URL}/static/images/OnboardLogoBlue.svg`} />
        </Link>
        <ul className="w-full ">
            { navList?.map((navLink, i) => {
                return (
                    <Link key={navLink.iconId} to={navLink.tab}>
                        <li className={`${handleActiveLink(navLink.pathName)} w-[180px] h-[46px] font-medium text-[16px] leading-[19.84px] flex items-center my-4`} >
                            <span className='pr-2' >
                                {
                                    navLink.name === 'Posts' ?
                                    <PostsIcon /> :
                                    <Icon
                                        id={
                                        navLink?.pathName === activeLink
                                            ? `${navLink?.iconId}2`
                                            : navLink?.iconId
                                        }
                                        width={20}
                                        height={20}
                                    />
                                }
                            </span>
                            <span className='' >{navLink.name}</span>
                        </li>
                    </Link>
                )
            }) }
        </ul>
    </div>
  )
}

export default SideNav